import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Span, Icon, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaDotCircle } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Pristine Shine El Araba Yıkama
			</title>
			<meta name={"description"} content={"Her Detayda Mükemmellik"} />
			<meta property={"og:title"} content={"Pristine Shine El Araba Yıkama"} />
			<meta property={"og:description"} content={"Her Detayda Mükemmellik"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Image
			md-top="300px"
			lg-top="0px"
			src="https://uploads.quarkly.io/6123949e957019001e284458/images/backgroung-hero.svg?v=2021-09-21T22:07:12.314Z"
			position="absolute"
			right="0px"
			bottom="auto"
			top="130px"
			z-index="1"
			lg-right="-200px"
			md-width="600px"
		/>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
		</Components.Header>
		<Components.Hero12>
			<Override slot="image" src="https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z" border-radius="30px" />
			<Override slot="text">
				Aracınızın hak ettiği muhteşem muameleyi gördüğü Pristine Shine Hand Car Wash'a hoş geldiniz.
			</Override>
			<Override slot="text1">
				Pristine Shine El Araba Yıkama'ya hoş geldiniz
			</Override>
			<Override slot="link" href="/about" />
			<Override slot="link1" href="/contact" />
		</Components.Hero12>
		<Section padding="160px 0 24px 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				flex-direction="column"
				md-width="100%"
				empty-min-width="80px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					position="static"
					lg-left="0px"
					md-width="100%"
					border-radius="10px"
					lg-max-height="366px"
					src="https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/2-1.jpg?v=2024-07-09T11:20:49.947Z"
					left="-140px"
					max-width="100%"
					object-fit="cover"
					srcSet="https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/2-1.jpg?v=2024-07-09T11%3A20%3A49.947Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/2-1.jpg?v=2024-07-09T11%3A20%3A49.947Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/2-1.jpg?v=2024-07-09T11%3A20%3A49.947Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/2-1.jpg?v=2024-07-09T11%3A20%3A49.947Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/2-1.jpg?v=2024-07-09T11%3A20%3A49.947Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/2-1.jpg?v=2024-07-09T11%3A20%3A49.947Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/2-1.jpg?v=2024-07-09T11%3A20%3A49.947Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-style="solid"
				width="50%"
				padding="16px 16px 16px 16px"
				display="flex"
				md-width="100%"
				empty-min-width="80px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				flex-direction="column"
				align-items="center"
			>
				<Box display="flex" margin="0px 0px 20px 0px" align-items="center" sm-width="100%" />
				<Text
					width="85%"
					lg-width="100%"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="normal 500 20px/1.6 --fontFamily-googleInter"
					color="--indigo"
					sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Uzman elde oto yıkama hizmetlerimiz, aracınızın her köşesinin mükemmellikle parıldamasını sağlar. İster büyük bir etkinlikten önce kapsamlı bir temizliğe ihtiyacınız olsun, ister sadece aracınızın görünümünü korumak isteyin, her zaman olağanüstü sonuçlar sunacağımıza söz veriyoruz.
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			lg-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-18"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6123949e957019001e284458/images/Group%20177.png?v=2021-08-24T00:44:18.633Z) 0% 30% /cover no-repeat scroll padding-box"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text
					margin="8px 0px 0px 0px"
					font="--lead"
					text-align="center"
					color="--green"
					sm-text-align="left"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Yoğun programınıza uyacak şekilde esnek çalışma saatlerine sahip, kolayca erişilebilen bir bölgede yer alan arabanızı yıkamak hiç bu kadar kolay olmamıştı.
						</Span>
					</Span>
				</Text>
				<Text
					as="h1"
					margin="4px 0px 12px 0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					text-align="center"
					sm-text-align="left"
				>
					Neden Pristine Shine El Araba Yıkama'yı Seçmelisiniz?
				</Text>
				<Box
					display="grid"
					width="100%"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					margin="60px 0px 0px 0px"
					max-width="1000px"
					md-grid-template-columns="1fr"
					md-grid-gap="6px"
					sm-grid-gap="32px"
				>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="fa"
							icon={FaDotCircle}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-indigo"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								Detaylara Rakipsiz Dikkat
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
								Arabaları temizlemekten fazlasını yapıyoruz; onları gençleştiriyoruz. Elde yıkama sürecimiz hiçbir noktanın gözden kaçırılmamasını sağlar ve otomatik sistemlerin başaramayacağı kapsamlı bir temizlik sunar.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="fa"
							icon={FaDotCircle}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-indigo"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								Yüksek kaliteli ürünler
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
								Yalnızca aracınızın cilasına zarar vermeyen ancak kire ve toza karşı dayanıklı olan en iyi temizlik ürünlerini kullanıyoruz. Çevre dostu ürünlerimiz, aracınızı parlatırken aynı zamanda çevreyi de korumamızı sağlar.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="fa"
							icon={FaDotCircle}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-indigo"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								Yetenekli Teknisyenler
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
								Ekibimiz mükemmellik tutkusuna sahip yüksek eğitimli profesyonellerden oluşmaktadır. Uzmanlıkları, her yıkamanın en yüksek kalite ve bakım standartlarını karşılamasını sağlar.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="fa"
							icon={FaDotCircle}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-indigo"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								Kapsamlı Hizmetler
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
								Dış yıkama ve cilalamadan iç detaylandırmaya kadar aracınızın en iyi görünmesini sağlamak için eksiksiz bir hizmet yelpazesi sunuyoruz. İhtiyaçlarınız ne olursa olsun, size uygun bir çözümümüz var.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section lg-padding="60px 0 40px 0" md-padding="30px 0 30px 0" margin="auto 0 auto 0" padding="60px 0 100px 0">
			<Text
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 40px 0px"
				margin="0px 0px 80px 0px"
				font="--headline2"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
			>
				Hakkımızda
			</Text>
			<Box
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				padding="0px 0px 0px 0px"
				border-style="solid"
				display="flex"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
				width="100%"
				border-color="rgba(66, 82, 207, 0.1)"
				md-border-color="rgba(66, 82, 207, 0.1)"
			>
				<Box display="flex" lg-width="50%" md-width="100%" width="30%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-3.jpg?v=2024-07-09T11:20:49.928Z"
						object-fit="cover"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						srcSet="https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-3.jpg?v=2024-07-09T11%3A20%3A49.928Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-3.jpg?v=2024-07-09T11%3A20%3A49.928Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-3.jpg?v=2024-07-09T11%3A20%3A49.928Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-3.jpg?v=2024-07-09T11%3A20%3A49.928Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-3.jpg?v=2024-07-09T11%3A20%3A49.928Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-3.jpg?v=2024-07-09T11%3A20%3A49.928Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-3.jpg?v=2024-07-09T11%3A20%3A49.928Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					padding="32px 17% 32px 62px"
					width="70%"
					justify-content="space-between"
					md-width="100%"
					md-padding="32px 30px 32px 0px"
					display="flex"
					flex-direction="column"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
				>
					<LinkBox
						bottom="auto"
						lg-right="16px"
						lg-top="49px"
						position="absolute"
						right="32px"
						top="32px"
						left="auto"
					>
						<Image
							width="48px"
							height="48px"
							top="32px"
							left="auto"
							lg-height="24px"
							src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z"
							position="static"
							right="32px"
							bottom="auto"
							lg-width="24px"
						/>
					</LinkBox>
					<Text
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
						margin="auto 0px auto 0px"
						font="--lead"
					>
						Pristine Shine Hand Car Wash olarak, bir arabanın bir ulaşım aracından daha fazlası olduğuna, yaşam tarzınızın bir yansıması olduğuna inanıyoruz. Misyonumuz, bizi geleneksel otomatik yıkamalardan ayıran benzersiz bir elde araba yıkama deneyimi sunmaktır. Yetenekli teknisyenlerimiz aracınızın boyasını temizlemek ve korumak için yalnızca üst düzey ürünler ve titiz teknikler kullanır ve herhangi bir çizilme veya hasar riski olmaksızın kusursuz bir yüzey elde edilmesini sağlar. Her araca kendimizinmiş gibi davranarak ayrıntılara verdiğimiz önemden büyük gurur duyuyoruz.
					</Text>
				</Box>
			</Box>
			<Box
				display="flex"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				md-border-width="0px 0px 2px 0px"
				margin="0px 0px 20px 0px"
				width="100%"
				border-style="solid"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				border-radius="10px"
				flex-wrap="wrap"
				min-height="284px"
				md-border-radius="0px"
			>
				<Box
					padding="32px 17% 32px 62px"
					width="70%"
					display="flex"
					position="relative"
					md-padding="32px 30px 32px 0px"
					flex-direction="column"
					justify-content="space-between"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
					md-width="100%"
					margin="auto 0px auto 0px"
				>
					<LinkBox
						lg-right="16px"
						lg-top="49px"
						position="absolute"
						right="32px"
						top="32px"
						left="auto"
						bottom="auto"
					>
						<Image
							src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z"
							left="auto"
							lg-height="24px"
							top="32px"
							bottom="auto"
							lg-width="24px"
							width="48px"
							height="48px"
							position="static"
							right="32px"
						/>
					</LinkBox>
					<Text
						margin="0px 0px 8px 0px"
						font="--lead"
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					>
						Pristine Shine Hand Car Wash'a her ziyaret, kalite ve müşteri memnuniyetine olan sarsılmaz bağlılığımızın bir kanıtıdır. Ekibimiz beklentilerinizin ötesine geçen olağanüstü bir yıkama deneyimi sunmaya kendini adamıştır. Temiz bir arabanın olumlu bir izlenim yarattığına inanıyoruz ve bunu her yıkamada başarmaya çalışıyoruz.
					</Text>
				</Box>
				<Box
					md-order="-1"
					width="30%"
					display="flex"
					lg-width="50%"
					md-width="100%"
				>
					<Image
						object-fit="cover"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						margin="-1px -1px -1px 0px"
						src="https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-2.jpeg?v=2024-07-09T11:20:49.946Z"
						srcSet="https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-2.jpeg?v=2024-07-09T11%3A20%3A49.946Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-2.jpeg?v=2024-07-09T11%3A20%3A49.946Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-2.jpeg?v=2024-07-09T11%3A20%3A49.946Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-2.jpeg?v=2024-07-09T11%3A20%3A49.946Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-2.jpeg?v=2024-07-09T11%3A20%3A49.946Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-2.jpeg?v=2024-07-09T11%3A20%3A49.946Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-2.jpeg?v=2024-07-09T11%3A20%3A49.946Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});